import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Countdown from "../components/countdown"

const currentDate = new Date();
const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

const LanPage = () => (
  <Layout>
    <SEO title="LAN" />
    <section className="lan">
      <h2>BI-Esport<br /> holder gratis LAN!</h2>
      <p>Er du mellem 8-17 år, gamer og klar på en sjov weekend i selskab med ligesindede?<br />
      Så kom og vær med til et brag af et LAN!</p>
      <h3>d. 10-12. Juli 2020</h3>
      <p>Vi åbner dørene kl. 20 d. 10/7 og lukker ned kl. 12 d. 12/7!
      Skynd dig at tilmelde dig!<br />
      Vi har kun 25 pladser..</p>
      <Countdown date={`2020-07-10T20:00:00`} />
    </section>
    <section className="signup lan">
      <div className="signup-wrapper">
        <div className="signup-card">
          <div className="game-header">
            <span>Billet</span>
          </div>
          <div className="content">
            <div className="schedule lan info">
              <span>Inkluderer:</span>
              <ul>
                <li>
                  Entré
                </li>
                <li>
                  B.Y.O.C
                </li>
                <li>
                  Madbillet (lør-søn)
                </li>
              </ul>
            </div>
            <div className="price">
              Pris: GRATIS
            </div>
          </div>
          <div className="button-wrapper">
            <a href="https://www.conventus.dk/dataudv/www/new_tilmelding.php?foreningsid=13880&gruppe=636566&skjul_nyt_medlem=0&skjul_allerede_medlem=0&sprog=da" target="_blank" className="signup-button" rel="noopener noreferrer">
              <div className="signup-text">
                  Tilmelding
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="lan">
      <h3>Huskeliste</h3>
      <ul className="col-3">
        <li>
          Billet (Digitalt på telefonen er tilstrækkeligt)
        </li>
        <li>
          ID i form af sygesikringsbevis, kørekort eller tilsvarende.
        </li>
        <li>
          Computer med opdaterede spil
        </li>
        <li>
          Skærm
        </li>
        <li>
          Tastatur, mus & musemåtte
        </li>
        <li>
          Batterier til trådløs mus/tastatur
        </li>
        <li>
          Headset
        </li>
        <li>
          Relevante kabler til computeren, (strømkabler helst uden jord)
        </li>
        <li>
          Netkabel (Minimum 6 meter)
        </li>
        <li>
          Nødvendige drivere til computeren og udstyr
        </li>
        <li>
          Toiletsager (Tandbørste og lign.)
        </li>
        <li>
          Håndklæder
        </li>
        <li>
          Liggeunderlag/luftmadras
        </li>
        <li>
          Sovepose el. dyne
        </li>
        <li>
          Behageligt tøj og sko
        </li>
        <li>
          Lommepenge til mad og drikke (Madbilletten dækker over hovedmåltider som morgenmad, frokost og aftensmad)
        </li>
      </ul>
    </section>
    <section className="lan">
      <h3>Regler</h3>
      <ul className="col-1">
        <li>Deltagelse til BI-Esport forudsætter overholdelse af de til enhver tid gældende regler for BI-Esport, samt dansk og international lovgivning.</li>
        <li>Regelsættet til BI-Esport kan ændres uden varsel og med øjeblikkelig virkning. Regelsættet vil hurtigst muligt blive opdateret på denne side.</li>
        <li>Alle deltagere, forældre og tilskuer skal følge anvisninger fra BI-Esport’s crew. </li>
        <li>Vi påtager os intet ansvar for hærværk, tyveri eller anden skade på udstyr.</li>
        <li>Vi påtager os intet ansvar for indholdet på deltageres computere.</li>
        <li>Vi forbeholder os retten til at omrokere og flytte folk, for at optimere pladsen.</li>
        <li>Vær opmærksom på at betalte billetter udelukkende kan refunderes, hvis arrangementet bliver aflyst.</li>
        <li>Af hensyn til brandregler SKAL midtergange holdes frie under hele arrangementet.</li>
        <li>Alle der begår sig på party-området skal have betalt indgang.</li>
        <li>Hvis gældende regler eller anvisninger ikke bliver overholdt, forbeholder BI-Esport sig retten til – uden varsel – at bortvise personer fra eventet og området (uden mulighed for refundering af billetten).</li>
        <li>Det er ikke tilladt at medbringe egne drikkevarer. Der må gerne medbringes vand.</li>
      </ul>
      <h4>Deltagere og pladser</h4>
      <ul className="col-1">  
        <li>Hver deltager får 90×50 cm. plads. Dette må ikke overskrides.</li>
        <li>Deltageren må ikke være til gene for sidemændene.</li>
        <li>Hver deltager får adgang til ét 230V udtag og ét netstik.</li>
        <li>Ønsker du at medbringe ekstraudstyr (f.eks. fysisk spilserver) skal dette godkendes af crew.</li>
        <li>Det er ikke tilladt at medbringe eget netværksudstyr (f.eks. Switch, router o.lign).</li>
        <li>Det er tilladt at medbringe egen stol.</li>
        <li>Støjniveauet skal holdes på moderat styrke. Crew vurderer, hvad moderat styrke er.</li>
        <li>Det påsatte armbånd skal bæres under hele eventet.</li>
        <li>Overnatning foregår kun i de dertil indrettede soveområder.</li>
        <li>Det er ikke tilladt at medbringe køleskab/tasker, der skal tilsluttes strøm.</li>
      </ul>
      <h4>Rygning</h4>
      <ul className="col-1">
        <li>Rygning er strengt forbudt indendørs.</li>
        <li>Ønsker du at ryge, skal det foregå udendørs — væk fra matriklen. </li>
        <li>Disse regler indebærer også brugere af e-cigaretter og lign.</li>
      </ul>
      <h4>Alkohol og stoffer</h4>
      <ul className="col-1">
        <li>Det er strengt forbudt at indtage alkohol og euforiserende stoffer under eventet.</li>
        <li>Møder man op i påvirket tilstand, har crew ret til at nægte adgang til eventet.</li>
        <li>Dette forbud gælder hele området.</li>
        <li>Indtagelse af alkohol udenfor BI-Esports område og efterfølgende brug af BI-Esports område såvel som sovesale er forbudt.</li>
      </ul>
      <h4>Fotografering</h4>
      <ul className="col-1">
        <li>Der vil løbende blive taget billeder af deltagere og gæster under afholdelsen af BI-Esport Lan. </li>
        <li>Disse billeder omfatter konkurrencebilleder, stemningsbilleder samt oversigtsbilleder af hallen.</li>
        <li>BI-Esport forbeholder sig retten til at offentliggøre disse billeder uden vederlag på hjemmesiden og som en del af reklame for kommende arrangementer.</li>
        <li>Skulle du opleve stødende, krænkende eller på en anden vis uacceptable billeder, kan du kontakte kontakt@bi-esport.dk for at få billedet fjernet øjeblikkeligt.</li>
      </ul>
      <h4>Andet</h4>
      <ul className="col-1">
        <li>Det er ikke tilladt at benytte segboards, løbehjul og andre køretøjer indendørs og udendørs i forbindelse med indgangen samt andre steder, hvor folk er tæt samlet.</li>
        <li>BI-Esport forbeholder sig retten til at logge alt datatrafik på hele BI-Esports netværk samt internetforbindelse, jfr. gældende lovgivning.</li>
        <li>Ophængning af plakater, skilte, mm. er ikke tilladt uden skriftlig tilladelse. Denne tilladelse kan forespørges på kontakt@bi-esport.dk.</li>
      </ul>
      <h4>Energidrik er IKKE tilladt</h4>
    </section>
  </Layout>
)

export default LanPage
